
import Vue from "vue";

export default Vue.extend({
  name: "NotFound",
  methods: {
    goToMain() {
      this.$router.push("/");
    },
    goBack() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        this.$router.push("/");
      }
    }
  }
});
